import React, {useEffect, useState} from 'react';
import SignupModalArea from "./signup.modal.style";
import {handleFindCustomerNonActive} from "../../../../usecases/customer/findCustomerNonActive";
import {handleCreateCustomer} from "../../../../usecases/customer/createCustomer";
import {handleUpdateCustomerPassword} from "../../../../usecases/customer/updateCustomerPassword";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement, IdealBankElement
} from "@stripe/react-stripe-js"
import {handleFindCreatedByCustomer} from "../../../../usecases/subscription/findCreatedByCustomer";
import {handleCreateSubscription} from "../../../../usecases/subscription/createSubscription";
import {logger} from "../../../../infrastructure/logger";
import {handleCreatePayment} from "../../../../usecases/transaction/createPayment";
import {handleCreatePaymentIntent} from "../../../../usecases/transaction/createPaymentIntent";
import {handleConfirmStripeSubscription} from "../../../../usecases/transaction/confirmStripeSubscription";
import {navigate} from "gatsby";
import {useCookies} from "react-cookie";
import {handleVerifyEmail} from "../../../../usecases/email/verifyEmail";

const SignUpModal = props => {

    const stripe = useStripe()
    const elements = useElements()

    const [email, setEmail] = useState("");
    const [cardHolder, setCardHolder] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");
    const [password, setPassword] = useState("");
    const [fullName, setFullName] = useState("");
    const [isTermsOfServicesChecked, setIsTermsOfServicesChecked] = useState(
        false
    )

    const [validationError, setValidationError] = useState(false);
    const [nameError, setNameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [buttonText, setButtonText] = useState("CONTINUE");

    const [currentStep, setCurrenStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [customerId, setCustomerId] = useState(undefined);
    const [subscriptionId, setSubscriptionId] = useState(undefined);

    const [cookie, setCookie] = useCookies([]);
    const [tomorrow, setTomorrow] = useState("");

    const [gclid, setGclid] = useState("");

    useEffect(() => {

        setGclid(cookie['customer.gclid']);
        const today = new Date();
        today.setDate(today.getDate() + 1)
        setTomorrow(today.toLocaleDateString('en-UK'))

    }, []);

    const handleCloseModal = () => {
        window.history.pushState({path: 'home'}, 'Home', '/');
        props.setModalState(false);
        const body = document.body;
        body.style.overflowY = 'auto';
        setCurrenStep(1);
        setButtonText("CONTINUE")
        setValidationError(false)
    }

    const handleCreateOrUpdateCustomer = () => {

        if (buttonText === "SENDING...") {
            return;
        }

        setValidationError(false);
        setPasswordError(false);
        setNameError(false);

        if (!fullName) {
            setNameError(true);
            setValidationError("Name cannot be empty");
            return;
        }

        if (email === '' || confirmEmail === '') {
            setValidationError("Email cannot be empty");
            return;
        }

        if (email !== confirmEmail) {
            setValidationError("Emails do not match");
            return;
        }

        if (!password) {
            setPasswordError(true);
            setValidationError("Password cannot be empty");
            return;
        }

        if (!isTermsOfServicesChecked) {
            setValidationError("Please, accept terms of use");
            return;
        }

        getCustomerNonActiveByEmail(email, password, fullName)

    }

    const getCustomerNonActiveByEmail = (email, password, fullName) => {
        setButtonText('SENDING...');
        handleFindCustomerNonActive(email, (error, response) => {

            if (error) {
                setValidationError("Sorry, something went wrong, try later");
                return;
            }

            let customerId = response.id ? response.id : false;
            setCustomerId(customerId);

            if (!customerId) {

                handleVerifyEmail(email).then((r) => {
                    if (!r.data.isValid) {
                        setButtonText('CONTINUE')
                        setValidationError("Unavailable email, please enter a valid email address");
                        return;
                    }

                    handleCreateCustomer(email, password, fullName, (error, response) => {
                        if (error) {
                            setValidationError("Cannot create account with this email");
                            setButtonText('CONTINUE')
                            return;
                        }
                        customerId = response.id;
                        setCustomerId(customerId);
                        nextModal();
                    });
                }).catch((e) => {
                    setValidationError("Something went wrong, try later")
                });
                return;
            }

            handleUpdateCustomerPassword(customerId, password, (error) => {
                if (error) {
                    setValidationError("Sorry, something went wrong, try later");
                    setButtonText('CONTINUE')
                    return;
                }

                nextModal();
            })


        })

    }

    const nextModal = () => {
        window.history.pushState({path: 'checkout'}, 'Checkout', '/checkout');
        setCurrenStep(2);
    }

    const validateEmail = email => {
        if (!email || email === '') {
            return true;
        }
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    const handlePayment = async () => {

        setLoading(true);
        setValidationError(false)

        if (loading) {
            return;
        }

        if (!stripe || !elements) {
            return;
        }

        if (!cardHolder) {
            setValidationError("Card holder cannot be empty");
            setLoading(false);
            return;
        }

        const cardElement = elements.getElement(CardNumberElement);

        const res = await stripe.createToken(cardElement)

        if (res.error) {
            logger.error("[error]", res.error)
            if (res.error.message) {
                setValidationError(res.error.message);
                setLoading(false);
            }
            return;
        }

        createOrRetrieveSubscription(res.token)

    }

    const createOrRetrieveSubscription = async (paymentMethod) => {

        try {
            const result = await handleFindCreatedByCustomer(customerId);
            let susId;

            if (result.data.length) {
                setSubscriptionId(result.data[0].id);
                susId = result.data[0].id;
            } else {
                const subscriptionResponse = await handleCreateSubscription(props.product.id, customerId, gclid);
                setSubscriptionId(subscriptionResponse.data.id);
                susId = subscriptionResponse.data.id;
            }

            await createNewTransaction(susId, paymentMethod);

        } catch (error) {
            setLoading(false);
            logger.error(error)
            setValidationError('Something was wrong, try later.');
        }


    }

    const createNewTransaction = async (subscriptionId, paymentMethod) => {
        const response = await handleCreatePayment(
            subscriptionId,
            paymentMethod.id,
            paymentMethod.card.country,
            paymentMethod.card.brand,
            paymentMethod.card.last4,
            paymentMethod.client_ip,
            cardHolder,
        );

        const transactionId = response.data.id;
        let intentResponse;

        try {
            intentResponse = await handleCreatePaymentIntent(transactionId);
        } catch (error) {
            setLoading(false);
            setValidationError(error.response.data.error);
            return;
        }

        const cardElement = elements.getElement(CardNumberElement);

        const stripeResponse = await stripe.confirmCardPayment(
            intentResponse.data.client_secret,
            {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: fullName,
                        email: intentResponse.data.email
                    }
                }
            }
        );

        if (stripeResponse.error) {
            setValidationError(stripeResponse.error);
            return;
        }

        const confirmResult = await handleConfirmStripeSubscription(transactionId);

        if (confirmResult.statusCode === 201) {

            const grantAuth = btoa(JSON.stringify({
                email: email,
                password: password,
                phone: props.phone,
                prefix: props.prefix,
                message: props.message,
                sender: props.sender,
            }));

            setTimeout(() => {
                navigate('/thank-you?grant=' + grantAuth);
            }, 1000);
        }

    }


    return (
        <SignupModalArea id="signUpModal" className={props.modalState ? 'flex' : 'hide'}>

            <div className="modal-container">
                <a className="close" onClick={() => {
                    handleCloseModal();
                }}><i className="icon close">
                    <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 20 20"
                         className="svg-fill">
                        <path
                            d="M11.61,10l7.6-7.59A1,1,0,1,0,17.8,1L10,8.8,2.2,1a1,1,0,0,0-1.7.7,1,1,0,0,0,.29.71L8.39,10,.79,17.59A1,1,0,1,0,2.2,19L10,11.2,17.8,19a1,1,0,0,0,1.7-.7,1,1,0,0,0-.29-.71Z"></path>
                    </svg>
                </i></a>
                <div className="scroller">
                    <div className="inner">
                        <div className="n-register">
                            <div className="track-num bg-primary">
                                <span>Send To</span> <span>{props.prefix} {props.phone}</span>
                            </div>
                            <div id="signUpStep" style={{display: currentStep === 1 ? 'block' : 'none'}}>
                                <h6>Create Account</h6>
                                <form id="signup-form" className="mt3">
                                    <div className="input-block">
                                        <label htmlFor="name">
                                            <span>Username</span> &zwnj;
                                            <small>*</small>
                                        </label>
                                        <input id="name" name="name"
                                               placeholder="John Doe"
                                               autoFocus="autofocus"
                                               required="required"
                                               type="text"
                                               className="input"
                                               value={fullName}
                                               onChange={event => setFullName(event.target.value)}
                                               style={{
                                                   border: nameError ? "1px solid red" : "1px solid #d1d1d7"
                                               }}
                                        />
                                    </div>
                                    <div className="input-block">
                                        <label htmlFor="email">
                                            <span>Email Address</span> &zwnj;
                                            <small>*</small>
                                        </label>
                                        <input id="email" name="email"
                                               placeholder="hello@yourmail.com"
                                               autoFocus="autofocus"
                                               required="required"
                                               type="email"
                                               className="input"
                                               value={email}
                                               onChange={event => setEmail(event.target.value)}
                                               style={{
                                                   border: validateEmail(email) !== true ? "1px solid red" : "1px solid #d1d1d7"
                                               }}
                                        />
                                    </div>
                                    <div className="input-block">
                                        <label htmlFor="confirmEmail">
                                            <span>Confirm Email Address</span> &zwnj;
                                            <small>*</small>
                                        </label>
                                        <input id="confirmEmail"
                                               name="confirmEmail"
                                               placeholder="hello@yourmail.com"
                                               required="required"
                                               type="email"
                                               className="input"
                                               value={confirmEmail}
                                               onChange={event => setConfirmEmail(event.target.value)}
                                               style={{
                                                   border: validateEmail(confirmEmail) !== true ? "1px solid red" : "1px solid #d1d1d7"
                                               }}
                                        />
                                    </div>
                                    <div className="input-block">
                                        <label htmlFor="password">
                                            <span>Password</span> &zwnj;
                                            <small>*</small>
                                        </label>
                                        <input id="password"
                                               name="password"
                                               placeholder="*********"
                                               required="required"
                                               type="password"
                                               className="input"
                                               value={password}
                                               onChange={event => setPassword(event.target.value)}
                                               style={{
                                                   border: !passwordError ? "1px solid #d1d1d7" : "1px solid red"
                                               }}
                                        />
                                    </div>
                                    <div className="input-block mt-15 checkbox-block">

                                        <input id="agree-1"
                                               name="agree-1"
                                               required="required"
                                               type="checkbox"
                                               className="inputinput checkbox"
                                               onChange={event =>
                                                   setIsTermsOfServicesChecked(event.target.checked === true)
                                               }/>
                                        <label htmlFor="agree-1">
                                            <span><span className="text-dark">I accept <a href="/terms" target="_blank"> terms of use</a></span></span> &zwnj;
                                            <small>*</small></label>
                                    </div>
                                    <button type="button" className="btn-primary" onClick={() => {
                                        handleCreateOrUpdateCustomer()
                                    }}>{buttonText}
                                    </button>

                                    <div className="alert alert-danger"
                                         style={{display: validationError ? 'block' : 'none'}}>
                                        {validationError}
                                    </div>
                                </form>
                            </div>
                            <div id="paymentStep" style={{display: currentStep === 2 ? 'block' : 'none'}}>
                                <h6>Activate Account</h6>
                                {props.product ?
                                    <h3 className="text-primary mt-50  mb-15">
                                        {props.product.free_trial}$
                                        <small> {props.product.name}</small>
                                    </h3>
                                    : ""
                                }

                                <form id="payment-form">
                                    <label>NAME ON CARD</label>
                                    <input
                                        className="input"
                                        type="text"
                                        name="cardHolder"
                                        placeholder="John Doe"
                                        id="cardHolder"
                                        onChange={event => setCardHolder(event.target.value)}
                                    />
                                    <label>CARD NUMBER</label>
                                    <CardNumberElement
                                        className="input"
                                        options={{
                                            style: {
                                                base: {
                                                    fontSize: "16px",
                                                    color: "#424770",
                                                    "::placeholder": {
                                                        color: "#aab7c4",
                                                    },
                                                },
                                                invalid: {
                                                    color: "#9e2146",
                                                },
                                            },
                                        }}/>
                                    <div className="half">
                                        <label>EXPIRATION</label>
                                        <CardExpiryElement
                                            className="input"

                                            options={{
                                                style: {
                                                    base: {
                                                        fontSize: "16px",
                                                        color: "#424770",
                                                        "::placeholder": {
                                                            color: "#aab7c4",
                                                        },
                                                    },
                                                    invalid: {
                                                        color: "#9e2146",
                                                    },
                                                },
                                            }}
                                        />
                                    </div>
                                    <div className="half">
                                        <label>CVC</label>
                                        <CardCvcElement
                                            className="input ml2"
                                            options={{
                                                style: {
                                                    base: {
                                                        fontSize: "16px",
                                                        color: "#424770",
                                                        "::placeholder": {
                                                            color: "#aab7c4",
                                                        },
                                                    },
                                                    invalid: {
                                                        color: "#9e2146",
                                                    },
                                                },
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <img src="/img/credit-card-icons-png.png" width="250"/>
                                    </div>
                                    <small style={{color: 'gray', fontSize: '10px', marginBottom: '10px'}}>
                                        *CVV number is a 3 digit numeric code behind the card
                                    </small>
                                    <button type="button" className="btn-primary" style={{marginTop: '20px'}}
                                            onClick={event => {
                                                handlePayment(event)
                                            }}> {loading ? "PROCESSING..." : "PROCEED"}
                                    </button>

                                    <div className="alert alert-danger"
                                         style={{display: validationError ? 'block' : 'none'}}>
                                        {validationError}
                                    </div>

                                    <div style={{marginTop: "5px"}}>
                                        <span style={{color: 'black', fontSize: '10px'}}>
                                            After your trial ends, you will be charged $4.99 per week starting
                                            &nbsp;{tomorrow}. You can always cancel before then directly from your dashboard.
                                            By starting the trial, you also agree to secret-sms.com terms and condition
                                        </span>
                                    </div>

                                </form>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

        </SignupModalArea>
    )

}

export default SignUpModal;
