import styled from 'styled-components';
import BannerBg from 'common/assets/image/app-minimal/backk.png';

const BannerArea = styled.section`
  background-color: #ffffff;
  background-image: url(${BannerBg});
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  padding-top: 200px;
  padding-bottom: 100px;
  padding-left: 60px;
  position: relative;
  @media (max-width: 1600px) {
    padding-top: 210px;
    padding-bottom: 170px;
  }
  @media (max-width: 1199px) {
    padding-top: 140px;
    padding-bottom: 140px;
    padding-left: 0;
  }
  @media (max-width: 575px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .Container {
    display: flex;
  }
  h6 {
    color: #ffd400;
    font-weight: 600;
    @media (max-width: 900px) {
      font-size: 14px;
    }
  }
  h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 1.21;
    letter-spacing: -2px;
    color: #ffffff;
    margin-bottom: 20px;
    @media (max-width: 1600px) {
      font-size: 30px;
      max-width: 414px;
    }
    @media (max-width: 574px) {
      font-size: 30px;
      max-width: 100%;
      line-height: 40px;
    }
  }
  textarea {
    resize: none;
    font-family: 'Arial',sans-serif;
    -webkit-border-radius: 10px !important;
    -moz-border-radius: 10px!important;
    border-radius: 10px!important;
  }
  
  p {
    font-weight: normal;
    font-size: 18px;
    line-height: 2.11;
    letter-spacing: -0.3px;
    color: #ffffff;
    margin-bottom: 0;
    max-width: 515px;
    opacity: 0.8;
    @media (max-width: 1600px) {
      font-size: 16px;
      max-width: 466px;
    }
  }

  .ButtonWrap {
    display: flex;
    margin-top: 20px;
    align-items: center;
    @media (max-width: 1600px) {
      margin-top: 20px;
    }
    @media (max-width: 575px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    span {
      font-weight: 500;
      font-size: 15px;
      color: #ffffff;
      opacity: 0.6;
      line-height: 1;
      margin-bottom: 0;
      margin-left: 25px;
      @media (max-width: 1600px) {
        font-size: 14px;
      }
      @media (max-width: 575px) {
        margin-left: 0;
        margin-top: 10px;
      }
    }
  }

  .Select {
    -webkit-border-top-left-radius: 30px;
    -webkit-border-bottom-left-radius: 30px;
    -moz-border-radius-topleft: 30px;
    -moz-border-radius-bottomleft: 30px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    display: inline-flex;
    line-height: 1;
    font-size: 16px;
    border: 0;
    padding: 19.5px;
    background: white;

    @media (max-width: 1600px) {
      font-size: 14px;
      padding: 15px;
    }
  }

  .Input {
    display: inline-flex;
    line-height: 1;
    font-size: 16px;
    border: 0;
    padding: 20px;
    width: 100%;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    
    @media (max-width: 1600px) {
      font-size: 14px;
      padding: 15px;
    }
    
    @media(max-width: 500px){
      width: 110px;
    }
    
    @media(max-width: 900px) {
      width: 100%;
      padding-left: 20px !important;
    }

  }

  .Button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    line-height: 1;
    color: #ffffff;
    padding: 21px 29px;
    background-color: #1FC27C;
    transition: all 500ms ease;
    border: 0;

    &:hover {
      background-color: #C29B46;
      color: white;
    }

    i {
      margin-left: 10px;
      position: relative;
      top: 1px;
    }

    @media (max-width: 1600px) {
      font-size: 14px;
      padding: 16px 23px;
    }
    @media (max-width: 575px) {
      width: 100%;
    }
  }

  .flag-container {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: white;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    border: 0;
    width: 100%;
  }

  .dial-select {
    min-width: 110px;
  }

  .dial-select > .toggle {
    padding: .25rem .75rem .25rem 1.5rem;
    cursor: pointer;
  }

  .dial-select > .toggle > img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20px;
    flex: 0 0 20px;
    max-width: 20px;
    height: 15px;
    border-radius: 3px;
    overflow: hidden;
    background-color: #f1f1f5;
    margin-right: .75rem;
    vertical-align: middle;
  }

  .dial-select > .toggle > span {
    color: black;
    margin: 0;
    font-size: 16px;
  }

  .dial-select > .FlagMenu > a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    padding: .25rem 1.5rem;
    font-size: .9rem;
    color: #000;
  }

  .dial-select > .FlagMenu > a:hover {
    background: #d5d5d5;
  }

  .dial-select > .FlagMenu > a > img {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 26px;
    flex: 0 0 26px;
    max-width: 26px;
    height: 19px;
    overflow: hidden;
    background-color: #f1f1f5;
    border-radius: 4px;
  }

  .dial-select > .FlagMenu > a > span:nth-child(2) {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 43px;
    flex: 0 0 43px;
    margin: 0 .75rem;
  }

  .dial-select > .FlagMenu > a > span:nth-child(3) {
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0;
  }

  .FlagMenu {
    position: absolute;
    top: 125%;
    left: 0;
    width: 100%;
    height: 280px;
    -webkit-box-shadow: 0 0 20px rgb(0 0 0 / 10%);
    box-shadow: 0 0 20px rgb(0 0 0 / 10%);
    border-radius: .75rem;
    background-color: #fff;
    overflow-x: hidden;
    overflow-y: auto;
    padding: .75rem 0;
    z-index: 5;
    display: none;
  }
  .visible{
    display: block;
  }
  .FlagMenu a, .FlagMenu span {
    color: black;
  }

  .FlagMenu a:active {
    color: black;
  }

  .bannerMoc {
    position: absolute;
    bottom: 145px;
    right: 70px;
    @media (max-width: 1600px) {
      max-width: 750px;
      right: 43px;
    }
    @media (max-width: 1199px) {
      max-width: 500px;
    }
    @media (max-width: 768px) {
      max-width: 350px;
      right: 20px;
      bottom: 170px;
    }
    @media (max-width: 575px) {
      display: none;
    }
  }
  #image-mob{
    @media (max-width: 768px) {
      display: none;
    }
  }
`;
export const Col = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  @media (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

export default BannerArea;
