import {Http} from "../../infrastructure/http"
const http = new Http()

export const handleListCountries = (callback) => {

    async function asyncCall() {

        const response = await new Promise(resolve => {
            http.get(
                "public/countries",
                {},
                (err, res) => {
                    err
                        ? resolve(err)
                        : resolve({
                            state: "success",
                            countries: res.data
                        })
                }
            )
        });

        response.state === "success"
            ? callback(null, response.countries)
            : callback(response)
    }

    return asyncCall()
}