import {Http} from "../../infrastructure/http"

const http = new Http()

export const handleCreatePayment = (subscriptionId, token, cardCountry, cardBrand, cardMasked, ip, cardHolder) => {

    return new Promise((resolve, reject) => {

        http.post(
            "public/transactions/payment",
            {
                subscription_id: subscriptionId,
                token: token,
                token_type: 'card',
                card_country: cardCountry,
                card_brand: cardBrand,
                card_masked: cardMasked,
                ip: ip,
                card_holder: cardHolder,
            },
            (err, res) => {

                if (err) {
                    reject(err);
                    return;
                }

                if (res.status > 299) {
                    reject(res.data.error);
                    return;
                }

                resolve({
                    statusCode: res.status,
                    data: res.data
                })
            }
        )
    });

}
