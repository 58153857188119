import React, {useState} from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import {Icon} from 'react-icons-kit';
import {androidArrowForward} from 'react-icons-kit/ionicons/androidArrowForward';
import {androidDone} from 'react-icons-kit/ionicons/androidDone';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import PricingArea, {
    TopHeading,
    Row,
    Col,
    PriceCard,
    CardTop,
    CardBody,
    CardFooter,
    PricingAmount,
} from './pricing.style';

const Pricing = (props) => {
    const [state, setState] = useState({
        toggle: true,
    });

    const dataHandle = () => {
        setState({
            ...state,
            toggle: !state.toggle,
        });
    };
    const Data = useStaticQuery(graphql`
    query {
      appMinimalJson {
        MONTHLY_PRICING_DATA {
          recommended
          title
          price
          tagline
          planLabel
          options {
            text
          }
          button {
            label
            link
          }
        }
        YEARLY_PRICING_DATA {
          recommended
          title
          price
          tagline
          planLabel
          options {
            text
          }
          button {
            label
            link
          }
        }
      }
    }
  `);

    return (
        <PricingArea id="pricing_section">
            <Container className="Container">
                <TopHeading>
                    <Heading as="h2" content="Send anonymous and secure SMS online"/>
                </TopHeading>
                <Row>
                    {Data.appMinimalJson.YEARLY_PRICING_DATA.map(
                        (
                            {
                                recommended,
                                title,
                                price,
                                tagline,
                                planLabel,
                                options,
                                button,
                            },
                            index
                        ) => (
                            <Col key={`pricing-card-key-${index}`}>
                                <PriceCard
                                    className={recommended === true ? 'recommended' : ' '}
                                >
                                    <CardTop>
                                        <Heading as="h3" content={title}/>
                                        <div>
                                            <div className="trial" style={{
                                                color: "white",
                                                fontSize: "25px",
                                                float: "left",
                                                marginRight: "70px"
                                            }}>
                                                {props.product ?
                                                    <div style={{width: "100%"}}>{props.product.free_trial}$</div>
                                                    : ""
                                                }
                                                <div style={{lineHeight: "14px"}}><span
                                                    style={{color: "white", fontSize: "16px", lineHeight: "14px"}}>24h Trial</span>
                                                </div>
                                            </div>
                                            <div style={{float: "left"}}><img src="/img/24-hours.png" width="50"/></div>
                                        </div>
                                        {props.product ?
                                            <PricingAmount>
                                                <Heading as="h4" content={props.product.amount + "$"}/>
                                                <Text as="p" content={tagline}/>
                                            </PricingAmount>
                                            : ""
                                        }
                                    </CardTop>
                                    <CardBody>
                                        <Text
                                            as="span"
                                            className="pricingLabel"
                                            content={planLabel}
                                        />
                                        <ul className="priceList">
                                            {options.map(({text}, index) => (
                                                <li key={`priceList-key-${index}`}>
                                                    <Icon size={18} icon={androidDone}/>
                                                    {text}
                                                </li>
                                            ))}
                                        </ul>
                                    </CardBody>
                                    <CardFooter>
                                        <Link to="/" className="priceBtn">
                                            {button.label}{' '}
                                            <Icon size={18} icon={androidArrowForward}/>
                                        </Link>
                                    </CardFooter>
                                </PriceCard>
                            </Col>
                        )
                    )}
                </Row>
            </Container>
        </PricingArea>
    );
};

export default Pricing;
