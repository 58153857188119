import {Http} from "../../infrastructure/http"

const http = new Http()

export const handleCreateCustomer = (email, password, name, callback) => {

    async function asyncCall() {

        const response = await new Promise(resolve => {
            http.post(
                "public/customers",
                {
                    email: email,
                    password: password,
                    name: name
                },
                (err, res) => {
                    err
                        ? resolve(err)
                        : resolve({
                            state: "success",
                            id: res.data
                        })
                }
            )
        });

        response.state === "success"
            ? callback(null, response.id ? response.id : false)
            : callback(response)
    }

    return asyncCall()

}