import React, {useEffect, useState} from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import {Icon} from 'react-icons-kit';
import {androidSend} from 'react-icons-kit/ionicons/androidSend';
import Box from 'common/components/Box';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import Container from 'common/components/UI/Container';
import BannerArea, {Col} from './banner.style';
import {handleListCountries} from "../../../usecases/country/listCountries";
import SignUpModal from "../../../custom/components/home/SignUpModal";
import {useCookies} from "react-cookie";

const Banner = (props) => {

    const [countries, setCountries] = useState([]);
    const [defaultFlag, setDefaultFlag] = useState("/img/flags/gb.svg");
    const [defaultPhoneCode, setDefaultPhoneCode] = useState("+44");
    const [selectedCountry, setSelectedCountry] = useState("US");
    const [toggleState, setToggleState] = useState(false);
    const [cookies, setCookie] = useCookies(['phone', 'message', 'sender']);
    const [phoneSelected, setPhoneSelected] = useState(false);
    const [messageSelected, setMessageSelected] = useState(false);
    const [senderSelected, setSenderSelected] = useState(false);
    const [modalState, setModalState] = useState(false);

    const getCountries = () => {

        handleListCountries((error, countries) => {
            if (error) {
                return;
            }
            if (countries) {
                setCountries(countries)
            }
        })

    }

    const selectPhoneCode = (element, iso, code) => {
        setSelectedCountry(iso);
        iso = iso.toLowerCase();
        setDefaultFlag("/img/flags/" + iso + ".svg");
        setDefaultPhoneCode("+" + code);

    }

    const toggleFlags = () => {
        setToggleState(!toggleState);
    }

    const handleSendButton = () => {
        window.history.pushState({path: 'create-account'}, 'Create Account', '/create-account');
        const d = new Date();
        d.setTime(d.getTime() + (60 * 60 * 1000));

        const phone = document.getElementById('phone');
        const sender = document.getElementById('sender');
        const message = document.getElementById('message');
        const phoneContainer = document.getElementById('phoneContainer');

        phoneContainer.style.border = '0';
        message.style.border = '0';

        if (!phone.value || phone.value === "") {
            phoneContainer.style.border = '2px solid red';
            return;
        }

        if (!message.value || phone.message === "") {
            message.style.border = '2px solid red';
            return;
        }

        setPhoneSelected(phone.value);
        setMessageSelected(message.value);
        setSenderSelected(sender.value);
        setCookie("phone", phone.value, {path: "/", expires: d});
        setCookie("message", message.value, {path: "/", expires: d});
        if (sender.value !== "") {
            setCookie("sender", sender.value, {path: "/", expires: d});
        }
        setModalState(true);

        const body = document.body;
        body.style.overflowY = 'hidden';

    }

    useEffect(() => {
        if (window.navigator.language) {
            if (/^es\b/.test(navigator.language)) {
                setDefaultFlag("/img/flags/es.svg");
                setDefaultPhoneCode("+34");
            }
        }
        getCountries();
        const phone = cookies && cookies.phone ? cookies.phone : false;
        const message = cookies && cookies.message ? cookies.message : false;
        const sender = cookies && cookies.sender ? cookies.sender : false;
        if (phone) {
            const phoneInput = document.getElementById('phone');
            phoneInput.value = phone;
            setPhoneSelected(phone);
        }
        if (message) {
            const messageInput = document.getElementById('message');
            messageInput.value = message;
            setMessageSelected(message);
        }
        if (sender) {
            const senderInput = document.getElementById('sender');
            senderInput.value = sender;
            setSenderSelected(sender);
        }

    }, []);

    const Data = useStaticQuery(graphql`
    query {
      appMinimalJson {
        BannerData {
          title
          text
          button {
            link
            label
          }
          image {
            childImageSharp {
              gatsbyImageData(
                width: 945
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          tagline
        }
      }
    }
  `);
    const {title, text, button, image, tagline} =
        Data.appMinimalJson.BannerData;

    return (
        <BannerArea id="banner_section">
            <SignUpModal
                phone={phoneSelected}
                prefix={defaultPhoneCode}
                message={messageSelected}
                sender={senderSelected}
                modalState={modalState}
                setModalState={setModalState}
                product={props.product}
            />
            <Container className="Container">
                <Col>
                    <Text as="h6" content="Message who you want, when you want"/>
                    <Heading as="h2" content="The best and most secure online SMS platform "/>
                    <Box className="ButtonWrap">
                        <div className="flag-container">
                            <input className="Input" id="sender" autoFocus="autoFocus" tabIndex="1" type="text"
                                   placeholder="Sender Name (Optional)"/>
                        </div>
                    </Box>
                    <Box className="ButtonWrap">
                        <div className="flag-container" id="phoneContainer">
                            <div className="dial-select" onClick={() => {
                                toggleFlags()
                            }}>
                                <a className="toggle">
                                    <img src={defaultFlag} width="20px" alt="US"/>
                                    <span>{defaultPhoneCode}</span>
                                </a>
                                <div className={toggleState ? 'visible FlagMenu' : 'FlagMenu'}>
                                    {countries && countries.map((item, i) => {
                                        return <a key={i} onClick={(e) => {
                                            selectPhoneCode(e, item.iso, item.phone_code)
                                        }}>
                                            <img src={`/img/flags/${item.iso.toLowerCase()}.svg`} alt={item.iso}
                                                 width="20px"/>
                                            <span>+{item.phone_code}</span>
                                            <span>{item.name}</span>
                                        </a>
                                    })}
                                </div>
                            </div>
                            <input className="Input" id="phone" autoFocus="autoFocus" tabIndex="1" type="phone"
                                   placeholder="07555256950"/>
                            <input type="hidden" id="country" value={selectedCountry}/>
                        </div>
                    </Box>
                    <Box className="ButtonWrap">

                        <textarea rows="5" className="Input" id="message" autoFocus="autoFocus" tabIndex="1"
                                  placeholder="Message"></textarea>

                    </Box>
                    <Box>
                        <div style={{
                            border: '0',
                            cursor: 'pointer',
                            borderRadius: '0px',
                            marginTop: '30px',
                            textAlign: 'center'
                        }}>
                            <button type='button' className="Button"
                                    style={{border: '0', cursor: 'pointer', borderRadius: '0px', width: '100%'}}
                                    onClick={() => {
                                        handleSendButton();
                                    }}>SEND SMS <Icon size={17} icon={androidSend}/></button>
                        </div>
                    </Box>
                </Col>
                <Col id="image-mob">
                    <div style={{width: "100%", textAlign: "center"}}>
                        <img src="/img/phone.png" width="500"/>
                    </div>
                </Col>
            </Container>
        </BannerArea>
    );
};

export default Banner;
