export const logger = {

    error: (...message) => {

        if (process.env.NODE_ENV === 'production') {
            return;
        }

        console.log(message);

    },

    info: (...message) => {

        if (process.env.NODE_ENV === 'production') {
            return;
        }

        console.info(message);

    }

}